/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import {
  collection, onSnapshot, query, where,
} from 'firebase/firestore';
import QRCode from 'react-qr-code';
import { firebaseFirestore } from '../firebase';

function Realtime() {
  const [queue, setQueue] = useState([]);
  const [waiting, setWaiting] = useState([]);
  const [shouldBeHidden, setShouldBeHidden] = useState(false);
  const [timeSinceMint, setTimeSinceMint] = useState(null);

  const queueRef = useRef(queue);
  queueRef.current = queue;

  const waitingRef = useRef(waiting);
  waitingRef.current = waiting;

  const shortenBlockchainAddress = (address) => {
    if (address === '' || address.length < 10) return address;
    const shortened = `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length + 1)}`;
    return shortened;
  };

  const updateShownItem = () => {
    if (waitingRef.current.length > 0) {
      const newEntry = waitingRef.current[0];
      const spliced = waitingRef.current.splice(1);
      const existing = queueRef.current.map((x) => x);
      existing.splice(0, 1);
      existing.push(newEntry);
      setWaiting(spliced);
      setQueue(existing);
    }
  };

  const minutesDifference = (dateOne, dateTwo) => {
    const d1 = new Date(dateOne).getTime();
    const d2 = new Date(dateTwo).getTime();
    return Math.round((d2 - d1) / 1000);
  };

  const setupListener = () => {
    try {
      const collectionQuery = query(collection(firebaseFirestore, 'realtime_mints'), where('created_ts', '>', new Date()));
      const unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
        const temp = [];

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            temp.push(change.doc.data());
          }
        });

        if (temp.length === 0) return;
        temp.forEach((item) => {
          const loadimg = new Image();
          loadimg.onload = () => {
            const existing = waitingRef.current.map((x) => x);
            const updatedQueue = existing.concat(temp);
            setWaiting(updatedQueue);
          };
          loadimg.src = item.image_url;
        });
      });

      return unsubscribe;
    } catch (e) {
      alert('There was an error, please check that you have the correct credentials to access this page.');
    }
    return null;
  };

  const updateTimeSinceMint = () => {
    // const firstQueueItemFormattedTime = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    const firstQueueItemFormattedTime = queueRef.current[0] ? new Date(queueRef.current[0].created_ts.seconds * 1000 + queueRef.current[0].created_ts.nanoseconds / 1000000) : null;

    if (firstQueueItemFormattedTime) {
      const secondsDiff = minutesDifference(firstQueueItemFormattedTime, new Date());

      if (secondsDiff >= 600) {
        setTimeSinceMint('>10m');
      } else if (secondsDiff >= 300) {
        setTimeSinceMint('>5m');
      } else if (secondsDiff >= 60) {
        setTimeSinceMint('>1m');
      } else {
        setTimeSinceMint(`${secondsDiff}s`);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = setupListener();
    if (unsubscribe != null) {
      return unsubscribe;
    }
    return () => { };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTimeSinceMint();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateShownItem();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (shouldBeHidden) {
      setTimeout(() => {
        setShouldBeHidden(false);
      }, 1000);
    }
  }, [shouldBeHidden]);

  return (
    <div className="flex bg-callback-black min-h-screen flex-col justify-center items-center">
      <div className={`transition-opacity duration-1000 ${shouldBeHidden ? ' opacity-0 hidden' : 'opacity-100 visible'} justify-center flex-col flex w-1/2`}>
        {
          queueRef.current.length > 0
            ? (
              <div className="h-screen">
                {
                  queueRef.current.map((obj, idx) => (
                    <div
                      style={{
                        zIndex: idx,
                      }}
                      className={`px-[2%] py-[5%] duration-300 h-full w-full absolute top-0 left-0 ${queueRef.current.length - 1 === idx ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                      key={`${obj.name}_${obj.created_ts}`}
                    >
                      <div className="flex flex-col h-full gap-32 justify-between">
                        <div className="pl-8 flex-1">

                          {obj.name && <h1 className="text-white text-12xl font-bold mb-2">{obj.name}</h1>}

                          {obj.description && <h1 className="mx-8 text-white text-7xl leading-[6rem]">{obj.description}</h1>}

                        </div>
                        <img src={obj.image_url} className="w-full self-center aspect-square object-contain" alt="main_image" />

                        <div className="flex flex-1 justify-between items-end pl-8">

                          <div className="flex-3 flex-col flex justify-center mx-8">

                            {obj.blockchain && (
                              <h1 className="text-white text-7xl font-bold mb-1">
                                {obj.blockchain}
                              </h1>
                            )}

                            {obj.contract_address && (
                              <h1 className="text-white text-7xl font-bold mb-1 text-ellipsis overflow-hidden">
                                {shortenBlockchainAddress(obj.contract_address)}
                              </h1>
                            )}

                            {obj.token_standard && (
                              <h1 className="text-white text-7xl font-bold mb-1">
                                {obj.token_standard}
                              </h1>
                            )}

                            {timeSinceMint && (
                              <h1 className="text-zinc-600 text-7xl mt-3">
                                Minted
                                {' '}
                                {timeSinceMint}
                                {' '}
                                ago
                              </h1>
                            )}

                          </div>

                          <div className="flex-2 text-right">
                            {obj.qr_link && obj.qr_link.length > 0
                              ? (
                                <div className="p-5  bg-white">
                                  <QRCode
                                    style={{ height: '100%', aspectRatio: 1 }}
                                    value={obj.qr_link}
                                  />
                                </div>
                              )
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            )
            : (
              <h1 className="text-white text-4xl text-center">Waiting for new mints...</h1>
            )
        }
      </div>

    </div>
  );
}

export default Realtime;
