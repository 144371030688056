import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import SignIn from './SignIn';
import { firebaseAuth } from '../firebase';
import Realtime from './Realtime';

const callbackLogo = require('../images/callback_logo.png');

function Content() {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    });
  }, []);

  const handleSignOut = async (event) => {
    event.preventDefault();
    await signOut(firebaseAuth);
  };

  return (
    <div className="flex-1 bg-callback-black h-screen">

      <img
        src={callbackLogo}
        className="absolute w-10 ml-5 mt-5"
        alt="callback_logo"
      />

      {isSignedIn && <button onClick={handleSignOut} type="button" className="text-white float-right w-full absolute text-right mt-3 pr-5">Sign out</button>}

      {
                isSignedIn
                  ? <Realtime />
                  : <SignIn />
            }

    </div>
  );
}

export default Content;
