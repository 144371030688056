import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../firebase';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginIsLoading, setLoginIsLoading] = useState(false);

  const handleEmailOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordOnChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormOnSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoginIsLoading(true);
      await signInWithEmailAndPassword(firebaseAuth, email, password);
    } catch (e) {
      alert('Sign in error, please check credentials are correct.');
      setLoginIsLoading(false);
    }
  };

  return (

    <div className="flex h-full items-center justify-center">

      <div className="bg-white w-1/3 p-6 rounded-xl">

        <h1 className="text-3xl text-black font-bold bg text-center">
          Sign in
        </h1>

        <form
          onSubmit={handleFormOnSubmit}
          className="flex flex-col"
        >
          <label className="p-2 mt-5">Email</label>
          <input className="p-2 border-2 border-black rounded" type="text" value={email} onChange={handleEmailOnChange} />

          <label className="p-2">Password</label>
          <input className="p-2 border-2 border-black rounded" type="password" value={password} onChange={handlePasswordOnChange} />

          <input type="submit" className="bg-white p-3 mt-5 border-2 rounded-xl border-black cursor-pointer" value={loginIsLoading ? 'Loading...' : 'Sign in'} />

        </form>

      </div>

    </div>
  );
}

export default SignIn;
