import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAsPmpU1_X8MC2hj5npxzKuEdwg8iXJ_nk',
  authDomain: 'stack-3a96c.firebaseapp.com',
  databaseURL: 'https://stack-3a96c-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'stack-3a96c',
  storageBucket: 'stack-3a96c.appspot.com',
  messagingSenderId: '468373819048',
  appId: '1:468373819048:web:24fe0c46242a5022363c9d',
  measurementId: 'G-K4D0SSH94R',
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

const db = getFirestore(firebaseApp);

export const firebaseFirestore = db;
